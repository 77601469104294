form {
  display: flex;
  flex-direction: column;

  >div {
    margin-bottom: 15px;
  }

  label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    width: fit-content;
  }

  input[type="text"],
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  input[type="checkbox"] {
    margin-left: 10px;
  }

  textarea {
    resize: vertical;
  }

  textarea[readonly] {
    background-color: #e9e9e9;
  }
}

h3 {
  margin-top: 20px;
}

textarea.generated-kubeconfig {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f4f4f4;
  box-sizing: border-box;
  font-family: monospace;
  font-size: 14px;
}

button {
  margin-top: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 14px;
}

button:hover {
  background-color: #0056b3;
}


.token-code-container {
  margin-top: 20px;
}

.token-code {
  background-color: #f5f5f5;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: break-word;
}