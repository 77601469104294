$button-p-bg-color: #007bff;
$button-p-hover-bg-color: #0056b3;
$button-s-bg-color: #28a745;
$button-s-hover-bg-color: #218838;

@mixin button-styles {
  width: auto;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

@mixin primary-button-styles {
  @include button-styles;
  background-color: $button-p-bg-color;
  color: white;

  &:hover {
    background-color: $button-p-hover-bg-color;
  }
}

@mixin secondary-button-styles {
  @include button-styles;
  background-color: $button-s-bg-color;
  color: white;

  &:hover {
    background-color: $button-s-hover-bg-color;
  }
}


textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: monospace;
  box-sizing: border-box; // Ensures padding and border are included in the element's total width and height
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    margin: 0;
  }

  button {
    @include secondary-button-styles;
  }
}

.secret-item {
  margin-bottom: 20px;

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  textarea {
    margin-bottom: 5px;
  }

  .primary-button {
    @include primary-button-styles;
  }

  .secondary-button {
    @include secondary-button-styles;
  }
}


.Toastify__toast {
  button {
    @include primary-button-styles;
  }
}
